import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={1}
        lesson={"Lesson 4"}
        color={"hs"}
        total_count={15}
        module_title="What Could Happen?"
      >
        <ContentWrapper>
          <H2 underline>Step 1</H2>
          <p>Imagine that you have just created a new product that helps people’s health or well-being. With 2–3 other class members, create a list of the types of things that you would tell people about your product so you could sell a lot of it. Keep your list of ideas in your science notebook.</p>
          <p>As you make your list, think about who you would tell about your product, what it does, why they should use it, and other general issues.</p>
          <p>Be ready to share your ideas in a class discussion.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;

